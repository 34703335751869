import Cookies from 'js-cookie'

const ACCESS_TOKEN = 'kv-dv-access-token'
const REFRESH_TOKEN = 'kv-dv-refresh-token'
const EXPIRES_ON = 'kv-dv-expires-on'

export function getToken() {
  return Cookies.get(ACCESS_TOKEN)
}

export function setToken(token) {
  Cookies.set(ACCESS_TOKEN, token.access_token || '')
  Cookies.set(REFRESH_TOKEN, token.refresh_token || '')


  const expiresIn = token.expires_in || 3600 - 5;
  const expires_on = Date.now() + (expiresIn * 1000);

  console.info("setToken", expires_on)

  Cookies.set(EXPIRES_ON, expires_on)
}

export function isExpired() {
  const expiresOn = Cookies.get(EXPIRES_ON) || 0;
  return expiresOn < Date.now();
}

export function getRefreshToken() {
  return Cookies.get(REFRESH_TOKEN)
}

export function setRefreshToken(token) {
  return Cookies.set(REFRESH_TOKEN, token)
}

export function removeToken() {
  Cookies.remove(ACCESS_TOKEN)
  Cookies.remove(REFRESH_TOKEN)
  Cookies.remove(EXPIRES_ON)
}

export function removeRefreshToken() {
  return Cookies.remove(REFRESH_TOKEN)
}

