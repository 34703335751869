/**
 * 全站权限配置
 *
 */
import vueRouter from './router'
import store from './store'
import {validatenull} from '@/util/validate'
import {getToken} from '@/util/auth'
import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
// NProgress.configure({showSpinner: false});
// const lockPage = store.getters.website.lockPage; //锁屏页
vueRouter.beforeEach((to, from, next) => {
  // const meta = to.meta || {};
  // const isMenu = meta.menu === undefined ? to.query.menu : meta.menu;
  // store.commit('SET_IS_MENU', isMenu === undefined);
  if (getToken() && getToken() !== 'undefined' ) {
    if (to.path === '/') { //如果登录成功访问登录页跳转到主页
      next()
    } else {
      
      //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
      if (store.getters.token.length === 0) {
        // store.dispatch('FedLogOut').then(() => {
          next({path: '/'})
        // })
      } else {
        next()
      }
    }
  } else {
    //判断是否需要认证，没有登录访问去登录页
    // if (meta.isAuth === false) {
       //判断是否需要认证，没有登录访问去登录页
    if (to.path === '/') {
      next()
    } else {
      next({path: '/'})
    }
      
    // } else {
      // next({path: '/'})
    // }
  }
})

// router.afterEach(() => {
//   NProgress.done();
//   let title = store.getters.tag.label;
//   let i18n = store.getters.tag.meta.i18n;
//   title = router.$avueRouter.generateTitle(title, i18n);
//   //判断登录页的情况
//   if (router.history.current.fullPath === "/login") {
//     title = "登录";
//   }
//   //根据当前的标签也获取label的值动态设置浏览器标题
//   router.$avueRouter.setTitle(title);
// });
