// import { Loading } from 'element-ui';
import {validatenull} from '@/echart/util'
import {checkUrl, getFunction} from '@/utils/utils'
import {getToken, isExpired} from "@/util/auth";
import axios from 'axios';
import store from "@/store";
import router from '@/router';
import website from "@/config/website";
import {baseUrl} from "@/config/env";
import {Base64} from "js-base64";
import {Message, MessageBox} from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

window.$glob = {
    url: '',
    params: {},
    query: {},
    headers: {}
};

function getGlobParams() {
    var query = window.location.search.substring(1);
    query = query.split("&");
    query.forEach(ele => {
        var pair = ele.split("=");
        window.$glob.params[pair[0]] = pair[1]
    })
}

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []

axios.defaults.baseURL = baseUrl;
//默认超时时间
axios.defaults.timeout = 300000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
        // loadingInstance = Loading.service({
        //   text: '拼命加载中',
        //   background: 'rgba(0,0,0,0)',
        //   spinner: 'el-icon-loading'
        // });

        getGlobParams();
        if (!checkUrl(config.url)) {
            config.url = window.$glob.url + config.url;
        }
        if (!validatenull(window.$glob.header)) {
            let header = getFunction(window.$glob.header)();
            config.headers = Object.assign(config.headers, header);
        } else {
            config.headers["Authorization"] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`
        }

        if (config.url.indexOf('/coe-auth/oauth/token') >= 0) {
            return config
        }

        //获取全局参数
        if (typeof (config.data) === 'object') {
            let data = {}
            if (!validatenull(window.$glob.query)) {
                data = getFunction(window.$glob.query)()
            }
            data = Object.assign(window.$glob.params, data)

            if (config.method === 'get') {
                config.params = Object.assign(config.params, data)
            } else if (config.method === 'post') {
                config.data = Object.assign(config.data, data)
            }
        }
        //headers中配置text请求
        if (config.text === true) {
            config.headers["Content-Type"] = "text/plain";
        }

        // const auth = getAuth()
        // if (auth) {
        //     config.headers["Authorization"] = `Basic ${auth}`;
        // }
        //让每个请求携带token
        const token = getToken()
        if (token) {
            const expired = isExpired();
            if (expired) {
                if (!isRefreshing) {
                    console.log('***refresh_token***')
                    isRefreshing = true;
                    store.dispatch("refreshToken").then(() => {
                        isRefreshing = false;
                        requests.forEach(cb => cb())
                        requests = []
                    }).catch(err => {
                        console.error('refresh token error: ', err);
                    })
                }

                return new Promise((resolve) => {
                    requests.push(() => {
                        console.info("token", getToken())
                        // 因为config中的token是旧的，所以刷新token后要将新token传进来
                        config.headers[website.tokenHeader] = "bearer " + getToken();
                        resolve(config)
                    })
                });
            }
            config.headers[website.tokenHeader] = `bearer ${token}`;
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
;
//HTTPrequest拦截
axios.interceptors.response.use(res => {
        //关闭 progress bar
        NProgress.done();

        // console.info("***resp***", res);
        //获取状态码
        const status = res.data.code || res.status;
        const statusWhiteList = website.statusWhiteList || [];
        const msg = res.data.msg || res.data.error_description || "未知错误";
        //如果在白名单里则自行catch逻辑处理
        if (statusWhiteList.includes(status)) {
            return Promise.reject(res);
        }

        const data = res.data;

        if (status === 401) {
            MessageBox.confirm('用户鉴权出错需要重新登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('FedLogOut').then(() => router.push({path: '/login'}));
            });
        } else if (status !== 200 && data && !data.success) {
            Message({message: msg, type: "error"});
            return Promise.reject(new Error(res));
        }

        return res;
    }, err => {
        NProgress.done();

        const status = err.status;
        const data = err.data;
        if (data.msg) {
            Message({message: data.msg, type: 'error'});
        }
        if (status === 401 && data.code !== 403) {
            MessageBox.confirm('用户鉴权出错需要重新登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('FedLogOut').then(() => router.push({path: '/login'}));
            });
        }
        return Promise.reject(new Error(err));
    }
);

export default axios;
