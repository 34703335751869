import request from "../axios";

export const loginByUsername = (
    tenantId,
    username,
    password,
    grantType,
    type,
    key,
    code
) =>
    request({
        url: "/blade-auth/oauth/token",
        method: "post",
        headers: {
            'Tenant-Id': tenantId,
            'Captcha-Key': key,
            'Captcha-Code': code,
            'User-Type': 'web_account',
        },
        params: {
            tenantId,
            username,
            password,
            grant_type: grantType,
            scope: "all",
            type
        }
    });

export const loginBySocial = (tenantId, source, code, state) =>
    request({
        url: "/blade-auth/oauth/token",
        method: "post",
        headers: {
            "Tenant-Id": tenantId
        },
        params: {
            tenantId,
            source,
            code,
            state,
            grant_type: "social",
            scope: "all"
        }
    });

export const refreshToken = (refresh_token, tenantId) =>
    request({
        url: "/blade-auth/oauth/token",
        method: "post",
        headers: {
            "Tenant-Id": tenantId
        },
        params: {
            tenantId,
            refresh_token,
            grant_type: "refresh_token",
            scope: "all"
        }
    });

export const registerGuest = (form, oauthId) =>
    request({
        url: "/coe-user/register-guest",
        method: "post",
        params: {
            tenantId: form.tenantId,
            name: form.name,
            account: form.account,
            password: form.password,
            oauthId
        }
    });

export const getButtons = () =>
    request({
        url: "/smartlims-sys/menu/buttons",
        method: "get"
    });

export const getCaptcha = () =>
    request({
        url: "/blade-auth/oauth/captcha",
        method: "get"
    });

export const logout = () =>
    request({
        url: "/blade-auth/oauth/logout",
        method: "get"
    });

export const getUserInfo = () =>
    request({
        url: "/blade-auth/oauth/user-info",
        method: "get"
    });

export const sendLogs = list =>
    request({
        url: "/blade-auth/oauth/logout",
        method: "post",
        data: list
    });

export const clearCache = () =>
    request({
        url: "/blade-auth/oauth/clear-cache",
        method: "get"
    });
export const getRoutes = (topMenuId) =>
    request({
        url: "/smartlims-sys/menu/routes",
        method: "get",
        params: {
            topMenuId
        }
    });
